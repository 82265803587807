import { createContext, useContext, useState } from "react";
import {
    Conversation,
    RudderstackAnalyticsData,
} from "../api/PatientServerDTOs";

export type ConversationState = {
    authenticatedWithNhs: boolean;
    setAuthenticatedWithNhs: (authenticatedWithNhs: boolean) => void;
    authenticatedWithDob: boolean;
    setAuthenticatedWithDob: (authenticatedWithDob: boolean) => void;
    conversationThread: Conversation | null;
    setConversationThread: (conversation: Conversation) => void;
    rudderstackAnalyticsData: RudderstackAnalyticsData;
    setRudderstackAnalyticsData: (
        analyticsData: RudderstackAnalyticsData,
    ) => void;
    workspaceName: string;
    setWorkspaceName: (workspaceName: string) => void;
};

export const defaultState: ConversationState = {
    authenticatedWithNhs: false,
    setAuthenticatedWithNhs: () => {},
    authenticatedWithDob: false,
    setAuthenticatedWithDob: () => {},
    conversationThread: null,
    setConversationThread: () => {},
    rudderstackAnalyticsData: {
        anonymousId: "00000000-0000-0000-0000-000000000000",
        organisationId: "",
        userId: "00000000-0000-0000-0000-000000000000",
    },
    setRudderstackAnalyticsData: () => {},
    workspaceName: "",
    setWorkspaceName: () => {},
};

export const ConversationContext = createContext<ConversationState | undefined>(
    undefined,
);
ConversationContext.displayName = "ConversationContext";

const ConversationProvider = ConversationContext.Provider;

const useConversation = (): ConversationState => {
    const context = useContext(ConversationContext);

    if (typeof window !== "undefined") {
        if (context === undefined) {
            throw new Error(
                "This component is a ConversationConsumer, it must be used within an ConversationProvider",
            );
        }

        return context;
    }

    return context ?? defaultState;
};

const contextState = () => {
    const [authenticatedWithNhs, setAuthenticatedWithNhs] =
        useState<boolean>(false);
    const [authenticatedWithDob, setAuthenticatedWithDob] =
        useState<boolean>(false);
    const [conversationThread, setConversationThread] =
        useState<Conversation | null>(null);
    const [rudderstackAnalyticsData, setRudderstackAnalyticsData] =
        useState<RudderstackAnalyticsData>({
            anonymousId: "00000000-0000-0000-0000-000000000000",
            organisationId: "",
            userId: "00000000-0000-0000-0000-000000000000",
        } as RudderstackAnalyticsData);
    const [workspaceName, setWorkspaceName] = useState<string>("");

    return {
        authenticatedWithNhs,
        setAuthenticatedWithNhs,
        authenticatedWithDob,
        setAuthenticatedWithDob,
        conversationThread,
        setConversationThread,
        rudderstackAnalyticsData,
        setRudderstackAnalyticsData,
        workspaceName,
        setWorkspaceName,
    };
};

export { ConversationProvider, useConversation, contextState };
